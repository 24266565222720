import { getDictListByNames } from '@/api/employmentSupport/dict'

const dict = {
  namespaced: true,
  state: {
    query: {
      name: null
    },
    dict: {}
  },
  mutations: {
    SET_QUERY: function (state, query) {
      state.query = query
    },
    SET_DICT: function (state, dict) {
      if (typeof dict === 'object') {
        state.dict[dict.name] = dict.detail
      }
      if (dict instanceof Array) {
        dict.forEach(item => {
          state.dict[item.name] = item.detail
        })
      }
    }
  },
  actions: {
    loadDict ({ commit }, dictNames) {
      getDictListByNames(dictNames).then(res => {
        if (res.success) {
          commit('SET_DICT', res.data)
        }
      })
    }
  },
  getters: {
    query: function (state) {
      return state.query
    },
    dict: function (state) {
      return state.dict
    }
  }
}
export default dict
