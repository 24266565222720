import request from '@/utils/request'

export function productType (parameter) {
  return request({
    url: '/api/supplier/employmentSupport/supplierProductCategoryController/query',
    method: 'get',
    data: parameter
  })
}

// 物资商城的列表
export function page (parameter) {
  return request({
    url: '/api/enterprise/employmentMall/enterpriseProductController/page',
    method: 'get',
    params: parameter
  })
}
// 物资商城的详情
export function enterpriseProductpageByDetail (parameter) {
  return request({
    url: `/api/enterprise/employmentMall/enterpriseProductController/${parameter.id}`,
    method: 'get',
    params: parameter
  })
}
// 物资商城的评价
export function employmentEvaluate (parameter) {
  return request({
    url: `/api/enterprise/employmentEvaluate/enterpriseProductEvaluationController/page`,
    method: 'get',
    params: parameter
  })
}

// 物资商城的供应商信息
export function employmentMall (parameter) {
  return request({
    url: `/api/enterprise/employmentMall/enterpriseSupplierController/query`,
    method: 'get',
    params: parameter
  })
}
// 加入购物车
export function addenterpriseCartController (parameter) {
  return request({
    url: `/api/enterprise/employmentMall/enterpriseCartController`,
    method: 'post',
    data: parameter
  })
}

// 提醒供应商发货
export function sendBuyMsg (params) {
  return request({
    url: `/api/back/employmentMessage/messageController/sendBuyMsg`,
    method: 'post',
    params: {
      ...params
    }
  })
}

// 购物车列表
export function employmentMallList (parameter) {
  return request({
    url: `/api/enterprise/employmentMall/enterpriseCartController/queryByUserId`,
    method: 'get',
    params: parameter
  })
}
// 购物车列表删除
export function employmentMallDelete (parameter) {
  return request({
    url: `/api/enterprise/employmentMall/enterpriseCartController`,
    method: 'DELETE',
    params: parameter
  })
}

// 购物车列表批量删除
export function employmentMallDeleteAll (parameter) {
  return request({
    url: `/api/enterprise/employmentMall/enterpriseCartController/batch`,
    method: 'DELETE',
    params: null,
    data: parameter
  })
}

// 计算购物车总价
export function employmentCarTotal (parameter) {
  return request({
    url: `/api/enterprise/employmentMall/enterpriseCartController/calculation`,
    method: 'POST',
    data: parameter
  })
}
// 确认订单
export function enterpriseOrderController (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController`,
    method: 'POST',
    data: parameter
  })
}

// 获取省
export function queryAllProvince (parameter) {
  return request({
    url: `/api/back/employmentEmploy/district/queryAllProvince`,
    method: 'get',
    params: parameter
  })
}
// 获取市区
export function queryAllDistrict (parameter) {
  return request({
    url: `/api/back/employmentEmploy/district/${parameter.pid}`,
    method: 'get',
    params: parameter
  })
}
// 新增收货地址
export function addAddress (parameter) {
  return request({
    url: `/api/enterprise/employmentLogistics/enterpriseShippingAddressController`,
    method: 'POST',
    data: parameter
  })
}

// 收货地址列表
export function Addresslist (parameter) {
  return request({
    url: `/api/enterprise/employmentLogistics/enterpriseShippingAddressController/page`,
    method: 'GET',
    params: parameter
  })
}
// 删除收货地址
export function DeleteAddress (parameter) {
  return request({
    url: `/api/enterprise/employmentLogistics/enterpriseShippingAddressController`,
    method: 'DELETE',
    params: parameter
  })
}
// 修改收货地址
export function ModflyAddress (parameter) {
  return request({
    url: `/api/enterprise/employmentLogistics/enterpriseShippingAddressController`,
    method: 'PUT',
    data: parameter
  })
}
// 新增订单
export function addOrder (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController`,
    method: 'POST',
    data: parameter
  })
}

// 订单支付接口（微信）
export function weChatPay (parameter) {
  return request({
    url: `api/back/employment-payment/wxPay/toPayAsPC`,
    method: 'post',
    data: parameter
  })
}
// 订单支付接口（微信）
export function zfbChatPay (parameter) {
  return request({
    url: `api/back/employment-payment/alipay/toPayAsPC`,
    method: 'post',
    data: parameter
  })
}
// 支付回调接口查看是否付款成功
export function payNativeStatus (parameter) {
  return request({
    url: `api/back/employment-payment/wxPay/payNativeStatus`,
    method: 'post',
    data: parameter
  })
}
// 订单列表
export function OrderList (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController/query`,
    method: 'get',
    params: parameter
  })
}
// 取消订单
export function OrderListDelete (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController`,
    method: 'DELETE',
    params: parameter
  })
}
// 上传图片
export function uploadImage (parameter) {
  return request({
    url: `/api/enterprise/employmentFile/EnterpriseFileController/uploadImage`,
    method: 'POST',
    data: parameter
  })
}

// 上传文件
export function uploadFile (parameter) {
  return request({
    url: `/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
    method: 'POST',
    data: parameter
  })
}

// 根据对应操作，修改状态
export function employmentOrderUpdate (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController/update`,
    method: 'PUT',
    params: parameter
  })
}

// 删除订单
export function deleteOrder (id) {
  return request({
    url: `/api/back/employmentOrder/order`,
    method: 'delete',
    params: {
      id
    }
  })
}

// 根据订单id查看订单详情
export function employmentDetail (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController/queryById`,
    method: 'get',
    params: parameter
  })
}
// 根据订单评价详情
export function employmentEvaluateDetail (parameter) {
  return request({
    url: `/api/enterprise/employmentEvaluate/enterpriseProductEvaluationController/${parameter.id}`,
    method: 'get',
    params: parameter
  })
}

// 根据订单已完成
export function employmentEvaluatequeryDone (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController/queryDone`,
    method: 'get',
    params: parameter
  })
}

// 商品评价修改
export function employmentEvaluateModfly (parameter) {
  return request({
    url: `/api/enterprise/employmentEvaluate/enterpriseProductEvaluationController`,
    method: 'PUT',
    data: parameter
  })
}

// 订单列表导出
export function download (parameter) {
  return request({
    url: `/api/enterprise/employmentOrder/enterpriseOrderController/download`,
    method: 'post',
    data: parameter
  })
}
// 查询字典项
export function getTypeDetail (params) {
  return request({
    url: `/api/back/employmentSupport/dictDetail/page`,
    method: 'get',
    params: params
  })
}
// 企业名片保存信息
export function enterpriseInfo (data) {
  return request({
    url: `/api/common/enterpriseInfo`,
    method: 'put',
    data
  })
}
// 企业名片保存信息
export function updateRead (data) {
  return request({
    url: `api/enterprise/employmentLearn/EnterpriseMaterialDemateController/updateRead`,
    method: 'put',
    data: {
      ...data,
      reading: 1
    }
  })
}
// 人工介入
export function createQuestion (data) {
  return request({
    url: `api/enterprise/employmentLearn/EnterpriseMaterialDemateController/createQuestion`,
    method: 'put',
    data
  })
}
