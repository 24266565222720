export const workObj = {
  1: '20万',
  2: '30-60万',
  3: '100万及以上'
}

export const levelObj = {
  0: '无',
  1: '初级',
  2: '中级',
  3: '高级',
  '-1': '特殊工种证书'
}

export const communicateObj = {
  1: '小学',
  2: '初中',
  3: '高中',
  4: '大专',
  5: '本科',
  6: '硕士研究生',
  7: '博士研究生'
}

export const userType = {
  1: '技能人员',
  2: '技术人员'
}

export const qualificationObj = {
  1: '安全员',
  2: '建造师'
}

export const sexType = {
  0: '男',
  1: '女',
  2: '其他'
}

export const contractType = [
  {
    label: '未签',
    value: '0'
  },
  {
    label: '已签',
    value: '1'
  }
]

export const roleType = [
  {
    label: '一级',
    value: '1'
  },
  {
    label: '二级',
    value: '2'
  },
  {
    label: '三级',
    value: '3'
  },
  {
    label: '四级',
    value: '4'
  }
]

export const BOARD_LODGING = {
  0: '包吃',
  1: '包住',
  2: '包吃住',
  3: '不提供吃住'
}

export const TOLL = {
  0: '单程',
  1: '双程',
  2: '不提供路费',
  3: '来程算工资'
}
