<template>
  <div class="nav-content">
    <div
      :class="{
        'transtion-hover': true,
        'nav-item': true,
        'nav-item-active': item.name === currentPageName,
      }"
      @mouseenter="handleEnter(item.component, item.title)"
      @mouseleave="handleLeave"
      @click="handleNavClick(item.name)"
      v-for="item in navList"
      :key="item.name"
    >
      {{ item.title }}
    </div>
    <div
      class="nav-component transtion-hover"
      @mouseenter="comEnter = true"
      @mouseleave="comEnter = false"
      :style="comStyle"
    >
      <div class="nav-component-content" v-show="component">
        <div class="component-img">
          <img :src="comSrc" alt />
        </div>
        <div class="component-detail" v-if="!Array.isArray(componentConfig)">
          <div class="detail-icon"></div>
          <div class="detail-title-zh">{{ componentConfig['title-zh'] }}</div>
          <!-- <div class="detail-title-cn">{{ componentConfig['title-cn'] }}</div> -->
          <div class="detail-content">
            <div class="detail-content-item" v-for="(item, index) in componentConfig.content || []" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="btn-theme btn" @click="handleNavClick(componentConfig.name)">{{ componentConfig.btnText }}</div>
        </div>
        <div class="component-detail" v-else>
          <div class="component-detail-header">
            <div class="detail-icon"></div>
            <div class="detail-title">{{ title }}</div>
          </div>
          <div class="component-detail-item" v-for="item in componentConfig" :key="item.name">
            <div class="detail-title" @click="handleNavClick(item.name)">{{ item['title-zh'] }}</div>
            <div class="detail-content">
              <div class="detail-content-item" v-for="(data, index) in item.content || []" :key="index">
                {{ data }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navImg1 from '@/assets/home/nav-img1.jpg'
import navImg2 from '@/assets/home/nav-img2.jpg'
import navImg3 from '@/assets/home/nav-img3.jpg'
import navImg4 from '@/assets/home/nav-img4.jpg'
import navImg5 from '@/assets/home/nav-img5.jpg'

const notPage = []

export default {
  name: 'Nav',
  components: {},
  computed: {
    currentPageName () {
      return this.$router.currentRoute.name
    },
    comStyle () {
      return {
        height: this.component && this.comEnter ? '510px' : '0px'
      }
    },
    componentConfig () {
      return this.component || {}
    },
    comSrc () {
      return Array.isArray(this.componentConfig) ? this.componentConfig[0].imgSrc : this.componentConfig.imgSrc
    }
  },
  mounted () {
    console.log(this.$router)
  },
  data () {
    return {
      navList: [
        {
          title: '首页',
          name: 'home',
          component: null
        },
        {
          title: '找活',
          name: 'biddingList',
          component: [
            {
              name: 'biddingList',
              imgSrc: navImg1,
              'title-zh': '站外招投标信息',
              'title-cn': 'Looking for work',
              content: [
                '平台与全国电力、化工、冶金行业28家招标机构合作，实时获取各行业、各集团、各地区的招标信息,通过平台智能筛选后,精准、实时地推送给会员企业。'
              ],
              btnText: '立即找活',
              handler: () => {}
            },
            {
              name: 'biddingListEnter',
              imgSrc: navImg1,
              'title-zh': '站内项目承接',
              'title-cn': 'Looking for work',
              content: [
                '平台提供站内生产企业项目需求信息公示，施工企业可实时查看与企业取得联系获得合作机会。'
              ],
              btnText: '立即找活',
              handler: () => {}
            }
          ]
        },
        {
          title: '找人',
          name: 'FindSomeone',
          component: {
            name: 'FindSomeone',
            imgSrc: navImg2,
            'title-zh': '找人',
            'title-cn': 'Looking for someone',
            content: [
              '平台依据国家规范，结合线下的实际应用将从事施工宝工程服务的人员细化成36个技木专业和64个技能工种，建立了分类科学、画像精准的数据库;编制了涵盖工种、地域、时间、价格等因素的标准模板，用工企业按标准模板发布需求，通过平台的智能匹配，实现供需双方的精准对接。'
            ],
            btnText: '立即找人',
            handler: () => {}
          }
        },
        {
          title: '找物',
          name: 'suppliesShop',
          component: {
            name: 'suppliesShop',
            imgSrc: navImg3,
            'title-zh': '找物',
            'title-cn': 'Looking for a gift',
            content: [
              '平台针对电力、化工、冶金行业施工宝工程所需的劳保、工具、备件、材料等物资开发了专属物资数据库，利用自身优势甄选各类物资源头厂商1000家（为确保价格合适，每个品种优选5家;为确保服务优质，每年替换1家)，对于大宗物资，平台每月组织一次价格谈判，及时掌控价格走势，编制价格指数信息报告，用数据赋能企业会员。'
            ],
            btnText: '立即找物',
            handler: () => {}
          }
        },
        {
          title: '找智',
          name: 'materialShop',
          component: [
            {
              name: 'materialShop',
              imgSrc: navImg4,
              'title-zh': '资料文档',
              'title-cn': 'Find Wisdom',
              content: [
                '平台针对电力、化工、冶金行业施工宝工程所需的技术、安全、管理资料建立了内容详实、质量上乘的资料库，会员可免费下载使用。'
              ],
              btnText: '立即找智',
              handler: () => {}
            },
            {
              name: 'videoMicroClass',
              imgSrc: navImg4,
              'title-zh': '视频微课',
              'title-cn': 'Find Wisdom',
              content: ['平台提供视频微课会员免费学习'],
              btnText: '立即找智',
              handler: () => {}
            },
            {
              name: 'videoList',
              imgSrc: navImg4,
              'title-zh': '执技云课堂',
              'title-cn': 'Find Wisdom',
              content: ['平台提供执业谩程、技能微课会员免费学习'],
              btnText: '立即找智',
              handler: () => {}
            }
          ]
        },
        {
          title: '找钱',
          name: 'Finance',
          component: {
            name: 'Finance',
            imgSrc: navImg5,
            'title-zh': '找钱',
            'title-cn': 'Change',
            content: [
              '企业会员通过平台对接的增信融资平台提交企业的经营情况和融资请求，增信融资平台对信息的真实性进行审核，通过后提交给合作的金融机构，获取资金支持。'
            ],
            btnText: '立即找钱',
            handler: () => {}
          }
        }
      ],
      component: null,
      comEnter: false,
      title: null
    }
  },
  methods: {
    handleEnter (component, title) {
      this.component = component
      this.title = title
      this.comEnter = true
    },
    handleLeave () {
      this.comEnter = false
    },
    handleNavClick (name) {
      if (notPage.includes(name)) {
        this.$message.error('正在加急开发中!')
      } else if (this.$route.name !== name) {
        this.$router.push({ name })
      } else {
        this.comEnter = false
      }
    }
  }
}
</script>

<style lang="less">
.nav-content {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  .nav-item {
    position: relative;
    z-index: 4;
    padding: 5px 24px;
    font-size: 16px;
    margin-right: 24px;
    height: 80px;
    line-height: 80px;
    cursor: pointer;
    &:hover {
      color: #1677ff;
      &::after {
        transition: all 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        position: absolute;
        height: 4px;
        content: '';
        display: inline-block;
        width: 46px;
        bottom: 0;
        left: calc(50% - 23px);
        background: #1677ff;
      }
    }
    &-active {
      color: #ffffff;
      &::before {
        content: '';
        display: inline-block;
        height: 36px;
        width: 100%;
        position: absolute;
        top: calc(50% - 14px);
        left: 0px;
        z-index: -1;
        background: #1677ff;
        border-radius: 40px;
      }
      &:hover {
        color: #ffffff;
        &::after {
          display: none;
        }
      }
    }
  }
  .nav-component {
    position: absolute;
    overflow: hidden;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 6px 8px #dddddd;
    z-index: -1;
    left: 0;
    top: 80px;
    .nav-component-content {
      width: 1200px;
      margin: 60px auto;
      display: flex;
      align-items: center;
    }
    .component-img {
      min-width: 520px;
      min-height: 390px;
      max-width: 520px;
      max-height: 390px;
      margin-right: 80px;
      img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }
    .component-detail {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 390px;
    }
    .detail-icon {
      width: 32px;
      height: 23px;
      background: url('../../../assets/home/nav-icon.png') no-repeat;
      background-size: 100% 100%;
      margin: 10px 0 32px 0;
    }
    .detail-title-zh,
    .detail-title {
      font-weight: 400;
      font-size: 36px;
      color: #1677ff;
    }
    .detail-title-cn {
      font-weight: 700;
      font-size: 30px;
      color: #333333;
    }
    .detail-content {
      flex: 1;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #666666;
      padding: 15px 0px;
      .detail-content-item {
        padding: 5px;
      }
    }
    .btn {
      height: 50px;
      width: 160px;
      border: 1px solid #1677ff;
      color: rgba(22, 119, 255, 1);
    }
    .component-detail-header {
      display: flex;
      align-items: center;
      .detail-title {
        position: relative;
        margin-left: 24px;
        top: -12px;
      }
    }
    .component-detail-item {
      .detail-title {
        color: #333333;
        cursor: pointer;
      }
    }
  }
}
</style>
