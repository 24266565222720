import { Modal } from 'ant-design-vue'
import Vue from 'vue'

function removeInstance(instance) {
    instance.$destroy()
    instance.$el.remove()
    instance = null
}

/**
 * 模拟Message创建全局modal弹窗
 * @param {*} option 
 * @returns
 */
export function customModal(option = {}) {
    var compInstance
    const { component = () => { }, componentOption = {} } = option
    const CustomModal = Vue.extend(Modal)
    var instance = new CustomModal(option)
    if (typeof component === 'object') {
        const Comp = Vue.extend(component)
        compInstance = new Comp(componentOption)
        compInstance.$mount()
        instance.$slots.default = [compInstance._vnode]
    } else {
        instance.$slots.default = [component()]
    }
    instance.$mount()
    instance.$on('cancel', () => {
        option.on && option.on.cancel && option.on.cancel()
        removeInstance(instance)
        compInstance && removeInstance(compInstance)
    })
    instance.$on('ok', () => {
        option.on && option.on.ok && option.on.ok()
        removeInstance(instance)
        compInstance && removeInstance(compInstance)
    })
    instance.closed = () => {
        removeInstance(instance)
    }
    document.body.appendChild(instance.$el)
    return instance
}