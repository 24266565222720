import moment from 'moment'
import Vue from 'vue'
import * as dictObj from './dict'
import request from '@/utils/request'
import * as numberMethods from './number'
import { customModal } from '@/components/common/custom-dialog'

const mixins = {
  data () {
    return {
      customModal: null
    }
  },
  computed: {
    isLogin () {
      return this.$store.getters.isLogin
    },
    userInfo () {
      return this.$store.getters.userInfo
    },
    _isVip () {
      return this.$store.getters.isVip
    },
    _vipInfo () {
      return this.$store.getters.vipInfo || {}
    }
  },
  created () {},
  mounted () {},
  methods: {
    ...numberMethods,
    formatTime (time, type = 'YYYY-MM-DD') {
      return time && moment(time).format(type)
    },
    handleGetDict (name) {
      return name ? dictObj[name] : dictObj
    },
    handleIsLogin () {
      if (!this.isLogin) {
        this.$confirm({
          title: '游客提示',
          content: '检测到您还未登录系统，请登录系统进行操作',
          onOk: () => {
            this.$router.push({
              name: 'login'
            })
          }
        })
        return this.isLogin
      }
      const { enterpriseInfoDto } = this.$store.getters.userInfo || {}
      const { status = '0' } = enterpriseInfoDto
      // 未认证
      if (status === '0') {
        this.customModal = customModal({
          propsData: {
            visible: true,
            title: 'CA认证提示',
            width: 600,
            footer: null
          },
          component: () => {
            return (
              <div
                style={{
                  textAlign: 'center'
                }}
              >
                <p>
                  系统的用工合同签署与采购合同签署业务，按照相关政策要求，需要先进行企业的CA认证。CA认证时间审核通过时间一般为2个工作日，为避免影响后续的业务操作。可根据此弹窗指引
                </p>
                <span
                  style="color: #1677FF;font-size: 18px;cursor: pointer"
                  onClick={() => {
                    this.$router.push({
                      name: 'enterpriseInfor'
                    })
                    this.customModal.closed()
                  }}
                >
                  前往认证 >>
                </span>
              </div>
            )
          }
        })
        return false
      } else if (status === '2') {
        this.customModal = customModal({
          propsData: {
            visible: true,
            title: 'CA认证提示',
            width: 600,
            footer: null
          },
          component: () => {
            return (
              <div
                style={{
                  padding: '20px 0px 0px 0px',
                  textAlign: 'center'
                }}
              >
                <p>您已发起CA认证，请耐心等待认证结果（认证结果一般在认证材料提交后1~2个工作日内产生）</p>
                <span
                  style="color: #1677FF;font-size: 18px;cursor: pointer"
                  onClick={() => {
                    this.$router.push({
                      name: 'enterpriseInfor'
                    })
                    this.customModal.closed()
                  }}
                >
                  继续提交认证材料 >>
                </span>
              </div>
            )
          }
        })
        return false
      } else if (status === '3') {
        this.customModal = customModal({
          propsData: {
            visible: true,
            title: 'CA认证提示',
            width: 600,
            footer: null
          },
          component: () => {
            return (
              <div
                style={{
                  textAlign: 'center'
                }}
              >
                <p>CA认证失败，请检查资料信息正确性，并重新进行认证。</p>
                <span
                  style="color: #1677FF;font-size: 18px;cursor: pointer"
                  onClick={() => {
                    this.$router.push({
                      name: 'enterpriseInfor'
                    })
                    this.customModal.closed()
                  }}
                >
                  前往认证 >>
                </span>
              </div>
            )
          }
        })
        return false
      }
      if (!this._isVip) {
        this.customModal = customModal({
          propsData: {
            visible: true,
            title: '会员充值',
            width: 600,
            footer: null
          },
          component: () => {
            return (
              <div
                style={{
                  textAlign: 'center'
                }}
              >
                <p>您当前不是平台vip，充值成功后可解锁权限。</p>
                <span
                  style="color: #1677FF;font-size: 18px;cursor: pointer"
                  onClick={() => {
                    this.$eventBus.$emit('vipModal')
                    this.customModal.closed()
                  }}
                >
                  立即充值 >>
                </span>
              </div>
            )
          }
        })
        return false
      }
      return true
    },
    /**
     * pdf文件在线下载
     * @param {*} path
     * @param {*} fileName
     */
    downloadFile (path, fileName = '合同附件') {
      request({
        method: 'get',
        url: path,
        responseType: 'blob'
      }).then((res) => {
        const blob = new Blob([res], {
          type: 'application/pdf'
        })
        const a = document.createElement('a')
        const URL = window.URL || window.webkitURL
        const href = URL.createObjectURL(blob)
        a.href = href
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(href)
      })
    }
  }
}

export default mixins

Vue.mixin(mixins)
