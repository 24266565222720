import VueCookies from 'vue-cookies'
import Vue from 'vue'
import { login, getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import store from '@/store'
import { welcome } from '@/utils/util'
import LoginType, { setIsLogin } from './isLogin'
import router from '@/router'
import { getVipInfo } from '@/api/vip-order'
import moment from 'moment'
import { setCurRoute } from '@/directive/perms'

function isEmpty (obj) {
  return obj === null || obj === undefined || !Object.keys(obj).length
}

Vue.use(VueCookies)

/**
 * 当浏览器缓存数据用户id变化时
 * 判断当前系统是否处于登录状态
 * 若未登录不处理
 * 若已登录则退出登录状态并跳转至登录页
 * @param {*} e 事件对象
 */
const storageChange = (e) => {
  if (e.key === 'USER_ID' && LoginType.isLogin) {
    setIsLogin(false)
    router.push('/login')
  }
}

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: null,
    isLogin: false,
    isVip: false,
    vipInfo: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_IS_LOGIN: (state, type) => {
      state.isLogin = type
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_USER_VIP_INFO: (state, vipInfo) => {
      state.isVip = !isEmpty(vipInfo)
      state.vipInfo = {
        ...vipInfo,
        startTime: moment(vipInfo.startTime).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(vipInfo.endTime).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            const result = response.data
            VueCookies.set(ACCESS_TOKEN, result.token, 12 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const result = {
              ...response.data,
              roleInfo: response.data.roleInfo
                .filter((item) => item.clientId === process.env.VUE_APP_CLIENT_ID)
                .sort((a, b) => a.level - b.level)
            }
            commit('SET_INFO', result)
            commit('SET_IS_LOGIN', true)
            commit('SET_NAME', { name: result.username, welcome: welcome() })
            commit('SET_AVATAR', result.avatar)
            setIsLogin(true)
            localStorage.setItem('USER_ID', result.userId)
            window.addEventListener('storage', storageChange)
            dispatch('getVipInfo', result.userId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getVipInfo ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        getVipInfo(userId).then((res) => {
          commit('SET_USER_VIP_INFO', res.data.enterpriseMemberTypeDto || {})
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          setCurRoute()
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_IS_LOGIN', false)
          setIsLogin(false)
          commit('SET_INFO', null)
          store.commit('SET_ROUTERS', [])
          VueCookies.remove(ACCESS_TOKEN)
          window.removeEventListener('storage', storageChange)
          resolve()
          window.location.reload()
        })
      })
    },
    // 登出
    Layout ({ commit, state }) {
      return new Promise((resolve) => {
        setCurRoute()
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_IS_LOGIN', false)
        setIsLogin(false)
        commit('SET_INFO', null)
        store.commit('SET_ROUTERS', [])
        VueCookies.remove(ACCESS_TOKEN)
        window.removeEventListener('storage', storageChange)
        resolve()
        // logout(state.token).then(() => {
        // })
      })
    }
  }
}

export default user
