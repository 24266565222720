<template>
  <div :class="wrpCls" style="line-height: 68px;">
    <router-link to="/workbench/workbenchNotice" style="color: #6C6C6C;">
      <a-badge style="margin-right:20px" :count="count">
        <a-icon type="bell" style="font-size:20px" />
      </a-badge>
    </router-link>
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
    <a href="/home/index" target="_blank" style="color: #6C6C6C">
      <a-divider type="vertical" />
      <a-icon type="bank" style="margin-right:5px" />首页
    </a>
    <!-- <select-lang :class="prefixCls" /> -->
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import { employmentMessagelist } from '@/api/notice'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      timer: null,
      count: 0,
      showMenu: true,
      currentUser: {}
    }
  },
  created () {
    this.currentUser = this.$store.getters.userInfo
    // eslint-disable-next-line no-return-assign
    this.$eventBus.$on('count', value => (this.count = value))
    employmentMessagelist({
      pageIndex: 1,
      pageSize: 10,
      status: 2
    }).then(res => {
      this.count = res.totalCount
    })
    this.timer = setInterval(() => {
      employmentMessagelist({
        pageIndex: 1,
        pageSize: 10,
        status: 2
      }).then(res => {
        this.count = res.totalCount
      })
    }, 1000 * 60)
  },

  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true
      }
    }
  },
  methods: {}
}
</script>
