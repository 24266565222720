const role = {
  namespaced: true,
  state: {
    query: {
      level: null,
      clientId: 'platform'
    }
  },
  mutations: {
    SET_QUERY: function (state, query) {
      state.query = query
    }
  },
  actions: {},
  getters: {
    query: function (state) {
      return state.query
    }
  }
}
export default role
