import request from '@/utils/request'

// 查询所有的会员类型
export function queryListByCondition () {
  return request({
    url: '/api/member/MemberTypeController/queryListByCondition',
    method: 'get',
    params: {
        status: 1
    }
  })
}

// 支付宝支付
export function payToAlipay (data) {
  return request({
    url: '/api/back/employment-payment/alipay/toPayAsPc/buyMember',
    method: 'post',
    data
  })
}

// 微信支付
export function payToWxPay (data) {
  return request({
    url: '/api/back/employment-payment/wxPay/toPayAsPc/buyMember',
    method: 'post',
    data
  })
}

// 微信支付
export function commitOrder (data) {
  return request({
    url: '/api/app/employmentOrder/appOrderController',
    method: 'post',
    data
  })
}

// 支付回调接口查看是否付款成功
export function payNativeStatus (parameter) {
  return request({
    url: `api/back/employment-payment/wxPay/payNativeStatus`,
    method: 'post',
    data: parameter
  })
}

// // 查询会员信息
// export function getVipInfo (userId) {
//   return request({
//     url: `/api/member/UserMemberController/queryByUserId/${userId}`,
//     method: 'get'
//   })
// }

// 查询会员信息
export function getVipInfo (userId) {
  return request({
    url: `/api/member/UserMemberController/queryEnterpriseMember/${userId}`,
    method: 'get'
  })
}

// 查询会员信息
export function getVipOrderList (params) {
  return request({
    url: `/api/member/MemberBuyOrderController/queryPageByCondition`,
    method: 'get',
    params
  })
}

// 新增地址
export function addAddress (data) {
  return request({
    url: `/api/member/MemberInvoiceMailingAddressController`,
    method: 'POST',
    data
  })
}

// 查询地址
export function getAddress () {
  return request({
    url: `/api/member/MemberInvoiceMailingAddressController/queryListByCondition`,
    method: 'get'
  })
}

// 编辑地址
export function updateAddress (data) {
  return request({
    url: `/api/member/MemberInvoiceMailingAddressController`,
    method: 'put',
    data
  })
}

// 删除地址
export function deleteAddress (ids) {
  return request({
    url: `/api/member/MemberInvoiceMailingAddressController`,
    method: 'delete',
    params: {
      ids
    }
  })
}

// 查询发表抬头
export function getInvoice (enterpriseId) {
  return request({
    url: `/api/member/MemberInvoiceHeaderController/queryOneByCondition`,
    method: 'get',
    params: {
      enterpriseId
    }
  })
}

// 新增发表抬头
export function addInvoice (data) {
  return request({
    url: `/api/member/MemberInvoiceHeaderController`,
    method: 'post',
    data
  })
}

// 编辑发表抬头
export function updateInvoice (data) {
  return request({
    url: `/api/member/MemberInvoiceHeaderController`,
    method: 'put',
    data
  })
}

// 新增会员发票申请开票的记录
export function MemberOrderInvoiceController (data) {
  return request({
    url: `/api/member/MemberOrderInvoiceController`,
    method: 'post',
    data
  })
}
