import request from '@/utils/request'

/**
 * 企业端-查询账户余额
 * @returns
 */
export function queryBalance(params) {
  return request
    .get('/api/back/MoneyController/queryBalance', {
      params,
    })
    .then((response) => response)
}

/**
 * 企业端-收支明细
 * @returns
 */
export function queryBalanceDetails(params) {
  return request
    .get('/api/back/AccountDetailsController/details', {
      params,
    })
    .then((response) => response)
}

/**
 * 企业端-申请账户余额充值
 * @returns
 */
export function postRecharge(params) {
  return request.post('/api/back/AccountDetailsController/recharge', params).then((response) => response)
}

/**
 * 企业端-更改账户手机号 验证验证码
 * @returns
 */
export function verificationCheckCode(params) {
  return request.post('/api/auth/verificationCheckCode', params).then((response) => response)
}

/**
 * 企业端-更改账户手机号
 * @returns
 */
export function updateUsername(params) {
  return request.post('/api/auth/updateUsername', params).then((response) => response)
}
