<template>
  <div
    :class="{
      'home-header': true,
      'home-header-bg-white': bgWhite,
    }"
  >
    <div class="mask"></div>
    <div class="logo" @click="$router.push({ name: 'home' })"></div>
    <div class="nav">
      <navTab />
    </div>
    <div class="action">
      <a-badge :count="count">
        <div class="action-item action-message" v-if="isLogin" @click="handleToMessage">消息</div>
      </a-badge>

      <div class="action-item action-console" v-if="isLogin" @click="handleToSystem">控制台</div>

      <a-badge :count="buyCarNumber">
        <div
          class="action-item action-car"
          style="font-size: 16px"
          @click="
            $router.push({
              path: '/supplies/supplies-car',
            })
          "
        >
          购物车
        </div>
      </a-badge>

      <div class="action-item action-login" v-if="!isLogin" @click="$router.push({ name: 'login' })">登录</div>
      <div class="action-item action-user" v-if="isLogin" @click="$refs.drawer.show()">
        <div class="user-icon"></div>
        <div class="user-content">我的账号</div>
      </div>
      <div class="action-item action-enroll" v-if="!isLogin" @click="$router.push({ name: 'register' })">免费注册</div>
    </div>
    <Drawer ref="drawer" />
    <DrawerCar ref="drawerCar" />
  </div>
</template>

<script>
import navTab from './nav.vue'
import Drawer from './drawer.vue'
import DrawerCar from './drawer-car.vue'
import { employmentMallList } from '@/api/materialShop'
import { employmentMessagelist } from '@/api/notice'

export default {
  name: 'HomeHeader',
  inject: ['getScrollTop'],
  components: {
    navTab,
    Drawer,
    DrawerCar
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo
    },
    bgWhite () {
      return this.$route.name !== 'home' || this.getScrollTop() > 627
    }
  },
  data () {
    return {
      buyCarNumber: 0,
      count: 0,
      timer: null
    }
  },
  created () {
    this.$eventBus.$on('count', (value) => (this.count = value))
    if (!this.isLogin) {
      return
    }
    employmentMessagelist({
      pageIndex: 1,
      pageSize: 10,
      status: 2
    }).then((res) => {
      this.count = res.totalCount
    })
    this.timer = setInterval(() => {
      employmentMessagelist({
        pageIndex: 1,
        pageSize: 10,
        status: 2
      }).then((res) => {
        this.count = res.totalCount
      })
    }, 1000 * 60)
  },
  mounted () {
    this.handleGetBuyNumber()
    this.$eventBus.$once('handleLayout', this.handleGetBuyNumber)
    this.$eventBus.$once('handleAddCar', this.handleGetBuyNumber)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null

    this.$eventBus.$off('handleLayout', this.handleGetBuyNumber)
    this.$eventBus.$off('handleAddCar', this.handleGetBuyNumber)
  },
  methods: {
    handleToSystem () {
      if (this.handleIsLogin()) {
        if (!this.$store.getters.addRouters.length) {
          this.$message.warning('当前账号不具备访问控制台的权限，请分配角色权限后尝试')
          return
        }
        const findUrl = (routers) => {
          const firstChild = routers[0]
          if (firstChild.children && firstChild.children.length) {
            return findUrl(firstChild.children)
          }
          return firstChild.path
        }
        const url = findUrl(this.$store.getters.addRouters)
        if (!url) {
          this.$message.warning('当前账号不具备访问控制台的权限，请分配角色权限后尝试')
        } else {
          window.open(url)
          console.log(url)
        }
      }
    },
    handleToMessage () {
      if (this.handleIsLogin()) {
        if (!this.$store.getters.addRouters.length) {
          this.$message.warning('当前账号不具备访问控制台的权限，请分配角色权限后尝试')
          return
        }
        window.open('/workbench/workbenchNotice')
      }
    },
    handleGetBuyNumber () {
      if (this.isLogin) {
        employmentMallList().then((res) => {
          this.buyCarNumber = res.data.reduce((number, item) => {
            return (number += item.products.length)
          }, 0)
        })
      } else {
        this.buyCarNumber = 0
      }
    }
  }
}
</script>

<style lang="less">
.home-header {
  position: relative;
  height: 80px;
  width: 100%;
  min-width: 1400px;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: space-between;
  color: #ffffff;
  transition: all 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 50.77%, rgba(255, 255, 255, 0.2) 99.46%);
  }
  .mask {
    height: 200px;
    width: 100%;
    position: absolute;
    z-index: -1;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }
  .logo {
    width: 220px;
    height: 47px;
    background-image: url('../../../assets/denglu/logo-white.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 30px;
    cursor: pointer;
  }
  .nav {
    flex: 1;
    padding: 0 100px;
  }
  .action {
    width: 330px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    .action-message{
      font-size: 16px;
    }

    &-item {
      font-weight: 550;
    }
    .action-user {
      width: 140px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      .user-icon {
        width: 46px;
        height: 28.85px;
        background-image: url('../../../assets/home/user-icon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .user-content {
        background: #1677ff;
        border-radius: 40px;
        padding: 1px 8px;
        margin-top: 9px;
        font-size: 12px;
      }
    }
    .action-enroll {
      width: 140px;
      height: 80px;
      background: #fc9a08;
      line-height: 80px;
      text-align: center;
    }
  }

  &:hover,
  &.home-header-bg-white {
    background: #ffffff;
    box-shadow: 0px 6px 8px #eeeeee;
    color: #666666;
    .mask {
      display: none;
    }
    .logo {
      background-image: url('../../../assets/denglu/logo.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .action {
      color: #333333;
    }
    .action-user {
      background: #1677ff;
      color: #1677ff;
      .user-content {
        background: #ffffff;
        border-radius: 40px;
        padding: 1px 8px;
        margin-top: 9px;
        font-size: 12px;
      }
    }
  }
}
</style>
