<template>
  <a-drawer
    title="购物车"
    placement="right"
    :visible="visible"
    width="360"
    :headerStyle="headerStyle"
    :bodyStyle="bodyStyle"
    @close="handleClose"
    wrapClassName="draw-car"
  >
    <div class="car-empty" v-if="!isLogin">
      <img src="../../../assets/home/car-empty.svg" alt />
      <div class="empty-title">您未登录账号，登录后可查看购物车！</div>
      <div class="login-btn" @click="$router.push({name: 'login'})">立即登录</div>
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: 'ShopCar',
  data () {
    return {
      visible: false,
      headerStyle: {
        background: '#1677FF',
        height: '80px',
        borderRadius: '0px'
      },
      bodyStyle: {
        padding: '0px',
        position: 'relative',
        height: 'calc(100% - 80px)'
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    handleClose () {
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.draw-car {
  .ant-drawer-header {
    .ant-drawer-title,
    .ant-drawer-close {
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
    }
    .ant-drawer-title {
      height: 47px;
      display: flex;
      align-items: center;
    }
    .ant-drawer-close {
      height: 80px;
    }
  }
  .car-empty {
    width: 300px;
    height: 100%;
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    img {
      width: 100%;
      height: 193px;
      vertical-align: middle;
    }
    .empty-title {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }
  }
  .login-btn {
    width: 320px;
    height: 40px;
    background: #1677ff;
    border-radius: 4px;
    font-weight: 300;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    position: absolute;
    bottom: 60px;
    cursor: pointer;
  }
}
</style>
