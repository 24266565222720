import './index.less'

export default {
  name: 'VCardList',
  props: {
    getDataApi: {
      type: Function,
      require: true
    },
    rowConfig: {
      type: Object,
      default: () => ({})
    },
    colConfig: {
      type: Object,
      default: () => ({
        span: 4
      })
    },
    initPagination: {
      type: Object,
      default: () => ({}) // 只作用于初始化，因为此组件自动处理分页，该参数是为了适用不同类型的分页器的情况考虑
    },
    renderItem: {
      type: Function
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    isToTop: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      cardList: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `总数 ${total} 条`
      }
    }
  },
  created () {
    this.pagination = {
      ...this.pagination,
      ...this.initPagination
    }
    this.$init()
  },
  computed: {
    isEmpty () {
      return !this.cardList || !this.cardList.length
    }
  },
  methods: {
    $init () {
      if (typeof this.getDataApi === 'function') {
        this.loading = true
        this.toTop()
        const { current: pageIndex, pageSize } = this.pagination
        this.getDataApi({ pageIndex, pageSize })
          .then((res) => {
            this.cardList = res.data
            this.pagination = {
              ...this.pagination,
              current: res.pageIndex,
              pageSize: res.pageSize,
              total: res.totalCount
            }
            if (res.data && !res.data.length && this.pagination.current > 1) {
              this.pagination.current--
              this.$init()
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    toTop () {
      if (this.isToTop) {
        this.cardList = []
      }
    },
    pageChange (page, pageSize) {
      console.log(page, pageSize)
      this.pagination.current = page
      this.pagination.pageSize = pageSize
      this.$init()
    },
    handlePrev () {
      let { current, pageSize } = this.pagination
      if (current === 1) return
      this.pageChange(--current, pageSize)
    },
    handleNext () {
      let { current, pageSize, total } = this.pagination
      if (current * pageSize >= total) return
      this.pageChange(++current, pageSize)
    },
    pageSizeChange (current, size) {
      this.pagination.current = current
      this.pagination.pageSize = size
      this.$init()
    },
    refresh () {
      this.$init()
    },
    pageInit () {
      this.pagination.current = 1
      this.$init()
    }
  },
  render () {
    return (
      <div class="v--card--list">
        <a-spin spinning={this.loading}>
          <div class="v-content--wrapper">
            {!this.isEmpty && (
              <a-row
                {...{
                  props: {
                    ...this.rowConfig
                  },
                  attrs: {
                    class: 'v-content--wrapper--row'
                  }
                }}
              >
                {this.cardList.map((item, index) => (
                  <a-col
                    {...{
                      props: {
                        ...this.colConfig
                      }
                    }}
                  >
                    {this.renderItem && this.renderItem({ item, index, data: this.cardList })}
                  </a-col>
                ))}
              </a-row>
            )}
            {this.isEmpty && this.$slots.empty}
          </div>
          {this.$slots.default}
        </a-spin>
        {this.showPagination && (
          <div class="v-pagination--wrapper">
            {this.$scopedSlots.pagination ? (
              this.$scopedSlots.pagination(this.pagination)
            ) : (
              <a-pagination
                {...{
                  props: {
                    ...this.pagination
                  },
                  on: {
                    change: this.pageChange,
                    showSizeChange: this.pageSizeChange
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}
