<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="isReload" />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isReload: true
    }
  },
  methods: {
    reload () {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  },
  computed: {
    locale () {
      return zhCN
    }
  }
}
</script>
<style>
.ant-modal-footer {
  border: 0;
}
.ant-table-tbody > tr > td {
  /* text-align: center; */
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  /* text-align: center !important; */
}
.ant-checkbox-group {
  width: 100%;
}
.ant-checkbox + span {
  white-space: nowrap;
}
</style>
