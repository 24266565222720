// with polyfills

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import '@/style/index.less'
import './component.js'

import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'
import { Pagination, Tree, Rate, Divider, Empty } from 'ant-design-vue'

import './permission' // permission control
import './global.less'
import './vue-mixins'
import './number.js'
import '@/components/common/v-spin/index'
import imgLazy from '@/directive/imgLazy.js'
import { getPerms } from '@/directive/perms.js'

Vue.config.productionTip = false
Vue.prototype.$getPerms = (persKey, key) => {
  // if (process.env.VUE_APP_CLIENT !== 'PLAT') {
  //   return true
  // }
  const pers = getPerms()
  if (key) {
    return !!pers[key][persKey]
  }
  return !!pers[[persKey]]
}

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component(Pagination.name, Pagination)
Vue.component(Tree.name, Tree)
Vue.component(Rate.name, Rate)
Vue.component(Divider.name, Divider)
Vue.component(Empty.name, Empty)

Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

const eventBus = new Vue()

Vue.prototype.$eventBus = eventBus
Vue.directive('imgLazy', imgLazy)
new Vue({
  router,
  store,
  // init localstorage, vuex, Logo message
  render: (h) => h(App)
}).$mount('#app')
