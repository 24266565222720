const dictDetail = {
  namespaced: true,
  state: {
    query: {
      value: null,
      label: null,
      dicId: null,
      dictName: null
    }
  },
  mutations: {
    SET_QUERY: function (state, query) {
      state.query = query
    }
  },
  actions: {},
  getters: {
    query: function (state) {
      return state.query
    }
  }
}
export default dictDetail
