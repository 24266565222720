/**
 * 全局自定义spining
 * 打开this.$spin.show()
 * 支持自定义展示option 参数配置详见 https://1x.antdv.com/components/spin-cn/
 * 关闭this.$spin.hide()
 */
import { Spin as VSpin } from 'ant-design-vue'
import Vue from 'vue'

const defaultOption = {
    size: 'large',
    tip: 'Loading...'
}

class Spin{
    constructor() {
        this.instance = null
    }
}

Spin.prototype.show = function (option = {}) {
    const VSpinCom = Vue.extend(VSpin)
    const instance = new VSpinCom({
        propsData: {
            ...defaultOption,
            ...option
        }
    })
    instance.$mount()
    instance.$el.className += ' v-custom-spining'
    document.body.appendChild(instance.$el)
    this.instance = instance
    return instance
}

Spin.prototype.hide = function () {
    this.instance.$destroy()
    this.instance.$el.remove()
    this.instance = null
}

var spin = new Spin()

Vue.prototype.$spin = spin

export default Spin