import request from '@/utils/request'

// 消息列表
export function employmentMessagelist (parameter) {
  return request({
    url: '/api/enterprise/employmentMessage/enterpriseMessageController/page',
    method: 'get',
    params: parameter
  })
}

// 系统查询系统消息
export const getSystemMsg = (params) => {
  return request.get('/api/back/employmentMessage/messageController/page', {
      params: {
          ...params
      }
  })
      .then(response => response)
}

// 是否未读
export function employmentMessageisRead (parameter) {
  return request({
    url: '/api/enterprise/employmentMessage/enterpriseMessageController/isRead',
    method: 'get',
    params: parameter
  })
}
// 修改状态
export function UpdataemploymentMessage (parameter) {
  return request({
    url: '/api/enterprise/employmentMessage/enterpriseMessageController',
    method: 'put',
    params: parameter
  })
}
// 删除信息
export function batch (data) {
  return request({
    url: 'api/enterprise/employmentMessage/enterpriseMessageController/batch',
    method: 'DELETE',
    data
  })
}
// 全部已读
export function updateByUserId () {
  return request({
    url: 'api/enterprise/employmentMessage/enterpriseMessageController/updateByUserId',
    method: 'put'
  })
}
