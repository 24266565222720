import { Input } from 'ant-design-vue'

function isNull(value) {
    return [undefined, null].includes(value)
}

export default {
    functional: true,
    render(h, context) {
        const { data, children, props, listeners } = context
        return h(Input, {
            ...data,
            props: {
                ...props,
                allowClear: props.type !== 'textarea' ? (isNull(props.allowClear) ? true : props.allowClear) : props.allowClear
            },
            on: {
                ...listeners,
                input: (e) => {
                    if (Array.isArray(listeners.input)) {
                        listeners.input[0] && listeners.input[0](e.target.value)
                        listeners.input[1] && listeners.input[1](e)
                    } else {
                        listeners.input && listeners.input(e.target.value)
                    }
                }
            }
        }, children)
    }
}