import { Icon } from 'ant-design-vue'

const menuIcon = Icon.createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
    extraCommonProps: {
        style: {
            'margin-right': '0px'
        }
    }
});
export function renderMenuIcon(type) {
    return {
        functional: true,
        render(h, context) {
            return h(menuIcon, {
                props: {
                    type
                }
            })
        }
    }
} 