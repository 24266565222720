<template>
  <div class="homeFrontPage__wrapper" v-if="reload">
    <a-affix :offset-top="0">
      <div class="page__header">
        <HomeHeader />
      </div>
    </a-affix>
    <div style="height: 80px" v-if="routeName !== 'home'"></div>
    <!-- <div class="page__banner" v-if="$route.path === '/home/index'">
      <a-carousel autoplay>
        <img src="/img/banner1.png" alt />
        <img src="/img/banner2.png" alt />
        <img src="/img/banner3.png" alt />
        <img src="/img/banner4.png" alt />
      </a-carousel>
    </div> -->
    <!-- <div class="page__breadcrumb" v-if="$route.path !== '/home/index'">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="{name: 'home'}">首页</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-for="(item,index) in breadcrumb" :key="item.path">
          <router-link :to="{path: item.path}" v-if="index + 1 !== breadcrumb.length">{{item.title}}</router-link>
          <span v-else>{{item.title}}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div> -->
    <div class="page__content">
      <div
        class="page__main"
        :class="{
          'max-page': !['home','balance'].includes(routeName),
        }">
        <router-view />
      </div>
    </div>
    <homeFooterNav/>
    <!-- <div class="page__footer"></div> -->
    <vipModal ref="vipModal" />
  </div>
</template>

<script>
import HomeHeader from '@/views/home/components/home-header.vue'
import homeFooterNav from '@/views/home/components/home-footer-nav.vue'
import vipModal from '@/views/home/components/vip-modal.vue'

import { Carousel } from 'ant-design-vue'

export default {
  name: 'HomeFrontPage',
  components: {
    HomeHeader,
    homeFooterNav,
    'a-carousel': Carousel,
    vipModal
  },
  provide () {
    return {
        getScrollTop: () => this.scrollTop
    }
  },

  data () {
    return {
      reload: true,
      scrollTop: 0,
      numCount: 1
    }
  },
  computed: {
    breadcrumb () {
      const { menu = [], title } = this.$route.meta
      if (!title) {
        return []
      }
      return [...menu, { title }]
    },
    routeName () {
      return this.$route.name
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.reload = false
        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.reload = true
        })
      }
    }
  },
  mounted () {
    this.$eventBus.$on('vipModal', this.vipModalShow)
    window.addEventListener('scroll', this.handleScrollTop, true)
  },
  beforeDestroy () {
    this.$eventBus.$off('vipModal', this.vipModalShow)
    window.removeEventListener('scroll', this.handleScrollTop, true)
  },
  methods: {
    vipModalShow () {
      this.$refs.vipModal.init()
    },
    handleScrollTop (e) {
        this.scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
    }
  }
}
</script>
<style lang="less">
.homeFrontPage__wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  min-width: 1400px;
  margin: 0 auto;
  flex-direction: column;
  background: #f5f4f8;
  .page__header {
    position: absolute;
    top: 0;
    min-height: 80px;
    width: 100%;
    z-index: 999;
  }
  .max-page,
  .page__breadcrumb {
    max-width: 1400px;
    min-width: 1400px;
    margin: 0 auto;
  }
  .page__main {
    background: #ffffff;
  }
  .page__breadcrumb {
    margin: 20px auto;
    .ant-breadcrumb {
      font-size: 16px;
    }
  }
  .page__footer {
    height: 20px;
  }
}
</style>
