import request from '@/utils/request'

// // 分页查询招投标订阅标签
// export function getPageData(parameter) {
//   return request.get('/api/enterprise/employmentCms/enterpriseTenderLabelController/page', {
//     params: {
//       ...parameter,
//     },
//   })
// }

// // 新增招投标订阅标签
// export function add(parameter) {
//   return request({
//     url: '/api/enterprise/employmentCms/enterpriseTenderLabelController',
//     method: 'post',
//     data: parameter,
//   })
// }

// // 批量删除招投标订阅标签
// export function deleteByIds(parameter) {
//   return request({
//     url: '/api/enterprise/employmentCms/enterpriseTenderLabelController/batch',
//     method: 'delete',
//     data: parameter,
//   })
// }

// // 分页查询招投标推送邮箱
// export function getEmailData(parameter) {
//   return request.get('/api/enterprise/employmentCms/enterpriseTenderMailboxController/page', {
//     params: {
//       ...parameter,
//     },
//   })
// }

// // 删除招投标推送邮箱
// export function deleteById(id) {
//   return request({
//     url: '/api/enterprise/employmentCms/enterpriseTenderMailboxController',
//     method: 'delete',
//     params: {
//       id,
//     },
//   })
// }

// // 新增招投标订阅标签
// export function addEmail(parameter) {
//   return request({
//     url: '/api/enterprise/employmentCms/enterpriseTenderMailboxController',
//     method: 'post',
//     data: parameter,
//   })
// }

export function add (model) {
  return request.post('/api/mail/TenderLabelController', model)
}

export function update (model) {
  return request.put('/api/mail/TenderLabelController', model)
}

export function getById (enterpriseId) {
  return request.get('/api/mail/TenderLabelController/queryOneByCondition', {
    params: {
      enterpriseId
    }
  })
}
