import './index.less'
export default {
    name: 'VTabs',
    props: {
        tabArr: {
            type: Array,
            required: true
        },
        value: [String, Number]
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.active = value
            }
        }
    },
    data() {
        return {
            active: ''
        }
    },
    methods: {
        handleClick(item) {
            if ((this.value || this.active) === item.value) {
                return
            }
            this.active = item.value
            this.$emit('change', item.value, item, this.tabArr)
            this.$emit('input', item.value)
        }
    },
    render() {
        return (
            <div class="v--tab__wrapper">
                {
                    this.tabArr.map(item => (
                        <div class={{
                            'v--tab--item': true,
                            'active': (this.value || this.active) === item.value
                        }} onClick={this.handleClick.bind(null, item)} >{item.label}</div>
                    ))
                }
            </div>
        )
    }
}