// eslint-disable-next-line
import * as loginService from '@/api/login'
// eslint-disable-next-line
import {  UserLayout, BasicLayout, BlankLayout, HomeFrontPage} from '@/layouts'
import { renderMenuIcon } from '@/components/common/menu-icon'

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  '403': () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
  '404': () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
  '500': () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),

  // 你需要动态引入的页面组件

  Role: () => import('@/views/employmentUser/Role'),

  materialData: () => import('@/views/material/index'),
  msgDemand: () => import('@/views/msg/index'),
  Bidding: () => import('@/views/bidding/index'),
  BiddingDetail: () => import('@/views/bidding/detail/index'),
  RecruitEvaluate: () => import('@/views/recruitUse/evaluate/index'),
  RecruitAttendance: () => import('@/views/recruitUse/attendance/index'),
  RecruitIndex: () => import('@/views/recruitUse/recruit/index'),
  RecruitUseCommission: () => import('@/views/recruitUse/commission/index'),
  materialDemandOrder: () => import('@/views/materialDemand/order/index'),
  materialDemandOrderDetail: () => import('@/views/materialDemand/order/detail'),
  materialDemandAddress: () => import('@/views/materialDemand/address/index'),
  biddingManagerIndex: () => import('@/views/biddingManager/index'),
  TenderingBiddingIndex: () => import('@/views/TenderingBidding/index'),
  learnMsgIndex: () => import('@/views/learnMsg/msg/index'),
  learnMsgorderMsg: () => import('@/views/learnMsg/orderMsg/index'),
  workbenchModle: () => import('@/views/workbench/modle/index'),
  workbenchNotice: () => import('@/views/workbench/notice/index'),
  workbenchProject: () => import('@/views/workbench/project/index'),
  workbenchRecruit: () => import('@/views/workbench/contract/recruit'),
  workbenchPurchase: () => import('@/views/workbench/contract/index'),
  materialShopSearch: () => import('@/views/materialShop/search/index'),
  materialShopDetail: () => import('@/views/materialShop/detail/index'),
  materialShopCar: () => import('@/views/materialShop/car/index'),
  materialShopOrder: () => import('@/views/materialShop/order/index'),
  workDemandIndex: () => import('@/views/workDemand/index'),
  systemEnterprise: () => import('@/views/system/enterprise/index'),
  systemAccount: () => import('@/views/system/account/index'),
  msgManagerRecruitUse: () => import('@/views/msgManager/msgRecruitUse/index'),
  msgManagerDemand: () => import('@/views/msgManager/msgDemand/index'),
  msgShopIndex: () => import('@/views/msgShop/index'),
  msgShopDetail: () => import('@/views/msgShop/detail'),
  recruitUseManager: () => import('@/views/recruitUse/recruitUseManager/index')
  // 'TestWork': () => import(/* webpackChunkName: "TestWork" */ '@/views/dashboard/TestWork')
}

// 暂时用静态路由代理动态路由，待开发完毕添加动态路由权限时统一调整

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = token => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav()
      .then(res => {
        const { data } = res
        const routers = generator(data)
        resolve(routers)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function formatPerms (perms) {
  return perms.reduce((per, item) => {
    if (item.type == 2) {
      if (item.children && item.children.length) {
        per[item.perms] = formatPerms(item.children)
      } else {
        per[item.perms] = true
      }
    }
    return per
  }, {})
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  if (routerMap.every(item => item.type == 2)) return
  return routerMap.map(item => {
    const { name, isShow, component, redirect, routeName, url: path, icon = null } = item
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path,
      // 路由名称，建议唯一
      name: routeName || item.id,
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: constantRouterComponents[component] || (() => import(`@/views${component}`)),
      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: name,
        icon: icon ? renderMenuIcon(icon) : null,
        perms: formatPerms(item.children)
      },
      hidden: !isShow
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    if (!item.children || (item.children && item.children.every(child => !child.isShow))) {
      currentRouter.hideChildrenInMenu = true
    }
    // 重定向
    redirect && (currentRouter.redirect = redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

// /**
//  * 数组转树形结构
//  * @param list 源数组
//  * @param tree 树
//  * @param parentId 父ID
//  */
// const listToTree = (list, tree, parentId) => {
//   list.forEach(item => {
//     // 判断是否为父级菜单
//     if (item.parentId === parentId) {
//       const child = {
//         ...item,
//         key: item.key || item.name,
//         children: [],
//         hidden: item.hidden || false
//       }
//       // 迭代 list， 找到当前菜单相符合的所有子菜单
//       listToTree(list, child.children, item.id)
//       // 删掉不存在 children 值的属性
//       if (child.children.length <= 0) {
//         delete child.children
//       }
//       // 加入到树中
//       tree.push(child)
//     }
//   })
// }
