/* eslint-disable no-sequences */
import './table.less'
import * as xlsx from 'xlsx'
export default {
  inheritAttrs: false,
  props: {
    sourceData: Function
  },
  data () {
    return {
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `总数 ${total} 条`,
        pageSizeOptions: ['10', '20', '30', '40', '50', '100', '500', '1000']
      },
      scroll: {
        ...(this.$attrs.scroll || {})
      },
      sorter: {},
      tableData: []
    }
  },
  computed: {
    headerShow () {
      return !!this.$slots.headerLeft || this.$attrs.hasOwnProperty('download')
    },
    columns () {
      const { columns } = this.$attrs
      return columns.map((item) => ({
        ...item,
        width: item.title === '序号' ? (item.width ? item.width : 60) : item.width
      }))
    }
  },
  created () {
    this.$initAttrs()
    this.$init()
  },
  mounted () {
    if (this.$attrs.extraHeight && this.$attrs.extraHeight !== 0) {
      this.handleSetTableStyle(window.innerHeight)
      window.addEventListener('resize', this.handleResize)
      this.$once('beforeDestroy:hook', () => {
        window.removeEventListener('resize', this.handleResize)
      })
    }
  },
  methods: {
    $initAttrs () {
      this.$set(this, 'scroll', {
        ...(this.$attrs.scroll || {})
      })
    },
    $init (val) {
      if (val) {
        this.loading = false
      } else {
        this.loading = true
      }
      if (this.sourceData && typeof this.sourceData === 'function') {
        const params = this.getQuery()
        this.sourceData(params)
          .then((res) => {
            this.tableData = res.data || []
            const { pageSize } = this.pagination
            this.pagination = {
              ...this.pagination,
              current: res.pageIndex || 1,
              pageSize: res.pageSize || pageSize,
              total: res.totalCount || 0
            }
            if (res.data && !res.data.length && this.pagination.current > 1) {
              this.pagination.current--
              this.$init()
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleResize (e) {
      const height = e.target.innerHeight
      this.handleSetTableStyle(height)
    },
    handleSetTableStyle (height) {
      const tableHeight = height - this.$attrs.extraHeight
      this.$set(this.scroll, 'y', tableHeight > 0 ? tableHeight : 1)
      this.$nextTick(() => {
        const tableEle = this.$el.querySelector('.ant-table-body')
        const realHeight = tableHeight > 0 ? tableHeight : 1
        tableEle.style.height = `${realHeight}px`
      })
    },
    refresh (val) {
      if (val) {
        this.$init(val)
      } else {
        this.$init()
      }
    },
    initPage (page = 1) {
      this.pagination.current = page
      this.$init()
    },
    getQuery () {
      const { current: pageIndex, pageSize } = this.pagination
      const { columnKey, order } = this.sorter
      return {
        pageIndex,
        pageSize,
        columnKey,
        order
      }
    },
    change (pagination, filter, sorter) {
      this.pagination = pagination
      this.sorter = sorter
      this.$init()
      this.$emit('change', pagination, filter, sorter)
    },
    /**
     * 文件下载
     */
    handleFileDown () {
      if (!this.tableData.length) {
        this.$message.error('当前表格暂无数据')
        return
      }
      const { columns, fileName = 'sheet', extraDownColumn = [] } = this.$attrs
      const downLoadCloumn = [...columns.filter((item) => item.download), ...extraDownColumn]
      const header = downLoadCloumn.reduce((obj, item) => {
        obj[item.dataIndex] = item.title
        return obj
      }, {})
      const downData = this.tableData.map((item, index) => {
        return downLoadCloumn.reduce((obj, col) => {
          obj[col.dataIndex] = this.handleFormatDownLoad(this.handleGetRowText(item, col.dataIndex), item, index, col)
          return obj
        }, {})
      })
      const fileData = xlsx.utils.json_to_sheet([header, ...downData], {
        skipHeader: true
      })
      const workbook = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(workbook, fileData)
      xlsx.writeFileXLSX(workbook, `${fileName}.xlsx`)
    },
    handleGetRowText (r, dataIndex) {
      if (Array.isArray(dataIndex)) {
        return dataIndex.reduce((data, key) => {
          if (data[key]) return data[key]
          return ''
        }, r)
      }
      return r[dataIndex]
    },
    handleFormatDownLoad (t, r, i, col) {
      const { formatDownLoad, customRender } = col
      if (formatDownLoad) {
        return formatDownLoad(t, r, i)
      }
      if (customRender) {
        return customRender(t, r, i)
      }
      return t
    }
  },
  render (h) {
    return (
      <div class="table__wrapper">
        {this.headerShow && (
          <div class="table__header_wrapper">
            {this.$slots.headerLeft}
            {this.$attrs.hasOwnProperty('download') && (
              <a-button
                type="primary"
                disabled={!this.tableData.length}
                onClick={this.handleFileDown}
                {...{
                  directives: this.$attrs.notPerms
                    ? []
                    : [
                        {
                          name: 'perms',
                          value: 'download',
                          arg: this.$attrs['download-arg'] || ''
                        }
                      ]
                }}
              >
                文件下载
              </a-button>
            )}
          </div>
        )}
        <div class="table__content__wrapper">
          <a-table
            {...{
              scopedSlots: {
                ...this.$scopedSlots
              },
              props: {
                ...this.$attrs,
                columns: this.columns,
                'data-source': this.tableData,
                loading: this.loading,
                scroll: this.scroll,
                pagination: this.$attrs.pagination !== undefined ? this.$attrs.pagination : this.pagination
              },
              attrs: {
                class: this.$attrs.extraHeight ? 'table--layout' : ''
              },
              on: {
                ...this.$listeners,
                change: (pagination, filter, sorter) => {
                  this.change(pagination, filter, sorter)
                  if (this.$listeners.change) {
                    this.$listeners.change(pagination, filter, sorter)
                  }
                }
              }
            }}
          >
            {this.$slots.default}
          </a-table>
        </div>
      </div>
    )
  }
}
