import Vue from 'vue'
import router from '@/router/index'
import store from '@/store'
import { update } from '@/api/biddingManager/index'
console.log(process.env)

var curRoute

export function setCurRoute () {
    curRoute = undefined
}

export function getPerms () {
    const currentRouterUrl = router.currentRoute.path
    if (curRoute && curRoute.path === currentRouterUrl) {
        return curRoute.meta.perms
    }
    const routerAsync = store.getters.addRouters
    cbFindPathByCur(routerAsync, currentRouterUrl)
    if (curRoute) {
        return curRoute.meta.perms
    }
    return {}
}

function cbFindPathByCur (routerList, curPath) {
    routerList.forEach(item => {
        if (curRoute && curRoute.path === curPath) {
            return
        }
        if (item.path === curPath) {
            curRoute = item
            return
        }
        if (item.children && item.children.length) {
            cbFindPathByCur(item.children, curPath)
        }
    })
}

Vue.directive('perms', {
    // bind: (el, binding, vnode) => {
    //     console.log(el, binding, vnode)
    // },
    inserted: (el, binding, vnode) => {
        // console.log(el, binding, vnode)
        const perms = getPerms() || {}
        const { arg, value } = binding
        if (arg) {
            if (!perms[arg] || !perms[arg][value]) {
                el.style.display = 'none'
            }
        } else {
            if (!perms[value]) {
                el.style.display = 'none'
            }
        }
    }
})
