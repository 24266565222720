const LoginType = {
    isLogin: false // 当前系统是否登录标识
}

/**
 * 设置系统登录标识方法
 * @param {Boolean} isLogin // 登录标识
 */
export function setIsLogin(isLogin) {
    LoginType.isLogin = isLogin
}

export default LoginType
