import axios from 'axios'
import store from '@/store'
import route from '@/router'
import VueCookeis from 'vue-cookies'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000 // 请求超时时间
})
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const { data, status } = error.response
    // 从 localstorage 获取 token
    if (status === 403) {
      notification.error({
        message: '游客提示',
        description: '您暂无权限查看此页面'
      })
      store.dispatch('Layout').then(() => {
        route.push({ name: 'login' })
      })
    }
    if (status === 401) {
      notification.error({
        message: '登录提示',
        description: '您的账号认证已过期，请重新登录'
      })
      store.dispatch('Layout').then(() => {
        route.push({ name: 'login' })
      })
    }
    if (status === 500) {
      notification.error({
        message: '系统错误',
        description: data.errMessage
      })
    }
    if (status === 400) {
      notification.error({
        message: '参数错误',
        description: data.errMessage
      })
    }
    if (status === 404) {
      notification.error({
        message: '错误提示',
        description: '接口路径错误，请联系网站管理人员'
      })
    }
    if (status === 423) {
      notification.error({
        message: '错误提示',
        description: data.errMessage
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = VueCookeis.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token && config.url !== '/api/enterprise/enterpriseClientAuthController/login') {
    config.headers['token'] = token
  }
  config.headers['clientId'] = process.env.VUE_APP_CLIENT_ID
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

const ExRequest = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL_EX,
  timeout: 60000 // 请求超时时间
})

ExRequest.interceptors.request.use(config => {
  return config
}, errorHandler)

// response interceptor
ExRequest.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request

export {
  ExRequest,
  installer as VueAxios,
  request as axios
}
