<template>
  <a-modal
    class="vip-modal-view"
    :title="title"
    width="1043px"
    :visible="visible"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-spin :spinning="loading">
      <div class="modal-content">
        <div class="tips">*服务类商品，购买后不可退换货，开通后立即生效。</div>
        <div class="vip-list">
          <div
            :class="{
              'vip-list-item': true,
              'vip-list-item-active': item.id === vipType,
            }"
            v-for="item in vipList"
            :key="item.id"
            @click="handleChangeVipType(item.id)"
          >
            <div class="vip-list-item-title">【施工宝】{{ item.typeName }}</div>
            <div class="vip-list-item-price">¥ {{ item.price }}</div>
            <div class="vip-list-item-time tips">
              *会员有效期为 <span>{{ item.time }}</span> 月 ，支付成功后立即生效
            </div>
          </div>
        </div>
        <div class="vip-bottom">
          <div class="left">
            <div
              :class="{
                'left-item': true,
                'left-item-active': item.id === payType,
              }"
              v-for="item in typeList"
              :key="item.id"
              @click="handleChangePayType(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="transfer-box" v-if="payType === '3'">
            <div class="transfer-tips">
              *转账成功后，请联系客服<a href="" style="margin-left: 8px">400-625-9698。</a>
            </div>
            <div class="company-box">
              <div class="label-content">
                <div class="info-label">对公银行账户号：</div>
                <div class="info-label">账户主体：</div>
                <div class="info-label">开户行信息：</div>
                <div class="info-label">电话：</div>
              </div>
              <div class="value-content">
                <div class="info-value">8031 5020 0055 593</div>
                <div class="info-value">鼎基智能云平台有限公司</div>
                <div class="info-value">青岛银行股份有限公司青岛国际创新园支行</div>
                <div class="info-value">400-625-9698</div>
              </div>
            </div>
          </div>
          <div class="right" v-else>
            <vueQr :text="codeUrl" :size="175" class="vueQr" v-if="vipType && payType !== '3'" ></vueQr>
            <div class="right-content">
              <div class="tips">*会员订单发票开具，请前往控制台-会员订单，申请开票。</div>
              <div class="tips">
                开通“平台vip”,可享受平台灵活用工发布、招聘、考勤管理、薪资发放、保险等全生命周期的用工管理服务；
              </div>
              <div class="tips">开通“平台vip”,可享受平台内项目承揽需求发布，让您的项目不再有“用工荒”烦恼；</div>
              <div class="tips">开通“平台vip”,可享受平台学习资料、视频微课免费学习，为企业赋能未来。</div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { queryListByCondition, commitOrder, payToAlipay, payToWxPay, payNativeStatus } from '@/api/vip-order'
import vueQr from 'vue-qr'

export default {
  components: {
    vueQr
  },
  data () {
    return {
      codeUrl: '',
      visible: false,
      loading: false,
      vipList: [],
      typeList: [
        // {
        //   id: '1',
        //   name: '微信支付'
        // },
        {
          id: '2',
          name: '支付宝支付'
        },
        {
          id: '3',
          name: '线下对公账户打款'
        }
      ],
      payType: '2',
      vipType: '',
      outTradeNo: ''
    }
  },
  computed: {
    title () {
      return this._isVip ? '续费会员' : '开通会员'
    }
  },
  methods: {
    async init () {
      this.visible = true
      this.loading = true
      const res = await queryListByCondition()
      this.vipList = res.data
      this.handleChangeVipType(this.vipList[0].id)
      this.loading = false
      this.setTimer()
    },
    handleChangePayType (id) {
      if (this.payType !== id) {
        this.outTradeNo = ''
        this.payType = id
        if (this.payType !== '3' && this.vipType) {
          this.handleOrder()
        }
      }
    },
    handleChangeVipType (id) {
      if (this.vipType !== id) {
        this.outTradeNo = ''
        this.vipType = id
        if (this.payType !== '3' && this.vipType) {
          this.handleOrder()
        }
      }
    },
    async handleOrder () {
      try {
        this.loading = true
        const vipGood = this.vipList.find((item) => item.id === this.vipType)
        const params = {
          orderAddCmd: {
            amount: vipGood.price,
            clientId: 'enterprise',
            orderType: 'B端会员购买',
            supplierId: 1,
            supplierName: '企业端',
            totalAmount: vipGood.price
          },
          orderProductAddCmdList: [
            {
              productId: vipGood.id,
              num: 1,
              price: vipGood.price
            }
          ]
        }
        const res = await commitOrder(params)
        this.outTradeNo = res.data
        const fun = this.payType === '1' ? payToWxPay : payToAlipay
        const data = await fun({ outTradeNo: res.data })
        this.codeUrl = this.payType === '1' ? data.data.codeUrl : data.data
      } finally {
        this.loading = false
      }
    },
    setTimer () {
      this.timer = setInterval(() => {
        if (this.outTradeNo) {
          payNativeStatus({ outTradeNo: this.outTradeNo }).then((res) => {
            if (res.data.status === '1') {
              this.$message.success('会员付款成功')
              this.$store.dispatch('getVipInfo', this.userInfo.userId)
              this.handleCancel()
            }
          })
        }
      }, 1000)
    },
    handleCancel () {
      this.timer && clearInterval(this.timer)
      this.visible = false
      this.outTradeNo = ''
      this.vipType = ''
      this.payType = '2'
      this.codeUrl = ''
    }
  }
}
</script>

<style lang="less">
.vip-modal-view {
  .ant-modal-body {
    padding: 0 50px 50px 50px;
  }
}
.modal-content {
  color: #333333;
  .tips {
    color: #666666;
    font-size: 12px;
    margin: 10px 0;
  }
  .vip-list {
    height: 176px;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    &-item {
      display: inline-block;
      width: 100%;
      height: 100%;
      width: 358px;
      margin-right: 20px;
      border: 2px solid #999999;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      &-active {
        border-color: rgba(22, 119, 255, 1);
      }
      &-title {
        color: #000000;
        font-size: 24px;
        font-weight: 500;
        margin-top: 20px;
      }
      &-price {
        font-size: 48px;
        color: #999999;
        font-weight: 600;
      }
      &-time {
        margin: 0;
        span {
          color: rgba(252, 154, 8, 1);
        }
      }
    }
  }
  .vip-bottom {
    margin-top: 18px;
    display: flex;
    align-items: center;
    border: 1px solid #d8d8d8;
    height: 192px;
    .left {
      min-width: 152px;
      height: 100%;
      background: #eeeeee;
      &-item {
        font-weight: 400;
        font-size: 16px;
        height: 41px;
        text-align: center;
        line-height: 41px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &::after {
          content: '推荐';
          display: block;
          height: 20px;
          width: 100px;
          line-height: 20px;
          background: #ff6600;
          color: #ffffff;
          font-size: 10px;
          position: absolute;
          transform: rotate(-45deg);
          left: -38px;
          top: 2px;
        }
        &-active {
          background: #ffffff;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
    .right {
      flex: 1;
      padding: 20px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      // img {
      //   width: 155px;
      //   height: 155px;
      //   vertical-align: middle;
      // }
      .right-content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .border-box {
      border: 1px solid #c4c4c4;
      padding: 15px 20px;
    }
    .transfer-box {
      padding: 0 20px;
      width: 100%;
      .transfer-tips {
        margin-bottom: 10px;
      }
      .company-box {
        padding: 9px 25px;
        background: #cbdffa;
        display: flex;
        .label-content {
          width: 122px;
        }
        .info-label {
          height: 24px;
          background: #1677ff;
          color: #ffffff;
          line-height: 24px;
          text-align: right;
          margin-bottom: 1px;
        }
        .value-content {
          flex: 1;
        }
        .info-value {
          height: 24px;
          text-indent: 10px;
          margin-bottom: 1px;
          background: #70abfd;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
