<template>
  <a-drawer
    title="账号"
    placement="right"
    :visible="visible"
    width="360"
    :headerStyle="headerStyle"
    :bodyStyle="bodyStyle"
    @close="handleClose"
    wrapClassName="draw-user"
  >
    <div class="drawer-user box-line box-card">
      <div class="title-box flex">
        <div class="title-icon"></div>
        <div class="title">我的账号</div>
      </div>
      <div class="user-detail flex">
        <a-avatar :size="60" :src="userInfo.imgPath || defaultPng" />
        <div class="user-detail-right">
          <div class="flex user-name-box">
            <div class="user-name">
              {{ userInfo.enterpriseInfoDto && userInfo.enterpriseInfoDto.enterpriseName }}
            </div>
            <div
              class="copy"
              @click="handleCopy(userInfo.enterpriseInfoDto && userInfo.enterpriseInfoDto.enterpriseName)"
            >
              复制
            </div>
          </div>
          <div class="flex user-id-box">
            <div class="user-id">账号ID：{{ userInfo.username }}</div>
            <div class="copy" @click="handleCopy(userInfo.username)">复制</div>
          </div>
          <div class="flex vip-not-box" v-if="!_isVip">
            <div class="vip-not-bg"></div>
            <div class="vip-btn" @click="handleVipModalShow">点击购买vip>></div>
          </div>
          <div class="vip-box" v-else>
            <div class="flex">
              <div class="vip-bg"></div>
              <div class="vip-btn" @click="handleVipModalShow">立即续费>></div>
            </div>
            <div class="vip-box-time">会员开始：{{ `${_vipInfo.startTime}` }}</div>
            <div class="vip-box-time">会员结束：{{ `${_vipInfo.endTime}` }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="balance-detail box-line box-card">
      <div class="title-box flex">
        <div class="title-icon"></div>
        <div class="title">账户总览</div>
      </div>
      <div class="balance flex">
        <div class="balance-title">余额：</div>
        <div class="balance-number">{{ enterpriseMoney }}</div>
        <div class="balance-btn" @click="handleToPage(true)">充值</div>
      </div>
      <div class="paymant flex">
        <div class="paymant-title">收支明细：</div>
        <div class="paymant-btn" @click="handleToPage(false)">查看</div>
      </div>
    </div>
    <div class="vip-detail box-card">
      <div class="title-box flex">
        <div class="title-icon"></div>
        <div class="title">专属权益</div>
      </div>
      <div class="vip-box">
        开通<a>“平台vip”</a>,可享受平台灵活用工发布、招聘、考勤管理、薪资发放、保险等全生命周期的用工管理服务；
        <br />
        开通<a>“平台vip”</a>,可享受平台内项目承揽需求发布，让您的项目不再有“用工荒”烦恼；
        <br />
        开通<a>“平台vip”</a>,可享受平台学习资料、视频微课免费学习，为企业赋能未来。
      </div>
      <div class="vip-btn" @click="handleVipModalShow" v-if="!_isVip">点此立即开通平台vip</div>
    </div>
    <div class="layout-btn-box">
      <div class="layout-btn" @click="handleLayout">退出登录</div>
    </div>
    <div class="layout-btn-box" v-if="userInfo.isAdmin === 1">
      <div class="layout-btn-edit" @click="editAccount">修改登录账号</div>
    </div>
    <a-modal v-model="editVisible" title="修改登录账号" on-ok="handleOk">
      <template slot="footer" v-if="accountFlag">
        <a-button key="submit" type="primary" @click="editHandleOk"> 下一步 </a-button>
      </template>
      <template slot="footer" v-else>
        <a-button key="submit" type="primary" @click="accountConfirm"> 确定 </a-button>
      </template>
      <a-form :form="formAccount" v-if="accountFlag">
        <a-form-item label="当前手机号/账号">
          <a-input disabled v-model="userInfo.username"></a-input>
        </a-form-item>
        <a-form-item label="当前手机号验证码">
          <a-input-search placeholder="请输入手机号验证码" v-model="formAccount.checkCode" @search="onSearch">
            <a-button slot="enterButton" :disabled="isDisabled">
              {{ buttonName }}
            </a-button>
          </a-input-search>
        </a-form-item>
      </a-form>
      <a-form-model :model="formAccount" v-else ref="ruleForm" :rules="rules">
        <a-form-model-item label="新手机号/账号" prop="username">
          <a-input placeholder="请输入要绑定的新号码" v-model="formAccount.username"></a-input>
        </a-form-model-item>
        <a-form-model-item label="新手机号验证码" prop="checkCode">
          <a-input-search placeholder="请输入手机号验证码" v-model="formAccount.checkCode" @search="onSearch">
            <a-button slot="enterButton" :disabled="isDisabled">
              {{ buttonName }}
            </a-button>
          </a-input-search>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-drawer>
</template>

<script>
import { handleCopy } from '@/utils/util'
import defaultPng from '@/assets/home/default.png'
import { queryBalance, verificationCheckCode, updateUsername } from '@/api/balance'
import { getSmsCaptcha, getInfo } from '@/api/login'

export default {
  name: 'Drawer',
  props: {
    type: [String, undefined]
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo || {}
    }
  },

  data () {
    return {
      visible: false,
      editVisible: false,
      formAccount: {},
      CountDown: 60, // 倒计时
      isDisabled: false, // 是否禁用按钮
      buttonName: '点击发送', // 按钮初始化文字
      accountFlag: true,
      editUserName: '',
      rules: {
        username: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        checkCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      headerStyle: {
        background: '#1677FF',
        height: '80px',
        borderRadius: '0px'
      },
      bodyStyle: {
        padding: '0px',
        position: 'relative',
        height: 'calc(100% - 80px)'
      },
      defaultPng,
      enterpriseMoney: '0.00'
    }
  },
  mounted () {},
  methods: {
    handleVipModalShow () {
      this.$eventBus.$emit('vipModal')
      this.hide()
    },
    handleToPage (isRecharge = false) {
      if (this.type === 'console') {
        window.open(`/home/balance?isRecharge=${isRecharge}`)
      } else {
        this.$router.push({
          path: '/home/balance',
          query: {
            isRecharge
          }
        })
      }
    },
    show () {
      this.visible = true
      queryBalance({
        enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId
      }).then((res) => {
        this.enterpriseMoney = res?.data?.money || '0.00'
      })
    },
    hide () {
      this.visible = false
    },
    handleClose () {
      this.visible = false
    },
    handleLayout () {
      this.$confirm({
        title: '系统提示',
        content: '确认退出登录吗？',
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          this.$store.dispatch('Logout').then(() => {
            this.$eventBus.$emit('handleLayout')
            this.hide()
            this.$router.push({ name: 'home' })
          })
        },
        onCancel () {}
      })
    },
    handleCopy (text) {
      handleCopy(text)
    },
    editAccount () {
      this.formAccount = {}
      this.accountFlag = true
      this.editVisible = true
    },
    editHandleOk () {
      if (this.formAccount.checkCode) {
        verificationCheckCode({
          username: this.userInfo.username,
          checkCode: this.formAccount.checkCode
        }).then((res) => {
          if (res.success) {
            this.CountDown = 0
            this.formAccount.checkCode = ''
            this.accountFlag = false
          } else {
            this.$message.error(res.errMessage)
          }
        })
      } else {
        this.$message.error('请输入验证码')
      }
    },
    accountConfirm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          updateUsername({
            userId: this.userInfo.userId,
            username: this.formAccount.username,
            checkCode: this.formAccount.checkCode
          })
            .then((res) => {
              if (res.success) {
                this.$store.dispatch('GetInfo')
                this.editVisible = false
                this.$message.success('修改成功')
              } else {
                this.$message.error(res.errMessage)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
    onSearch () {
      if (this.accountFlag) {
        this.isDisabled = true
        const timerId = setInterval(() => {
          this.CountDown--
          this.buttonName = this.CountDown + '秒后重试'
          if (this.CountDown <= 0) {
            clearInterval(timerId)
            this.buttonName = '重新发送'
            this.CountDown = 60
            this.isDisabled = false
          }
        }, 1000)
        const params = {
          phone: this.userInfo.username,
          configId: 9
        }
        getSmsCaptcha(params)
          .then((res) => {
            if (res.success) {
              this.$message.success('发送成功')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.isDisabled = true
        const timerId = setInterval(() => {
          this.CountDown--
          this.buttonName = this.CountDown + '秒后重试'
          if (this.CountDown <= 0) {
            clearInterval(timerId)
            this.buttonName = '重新发送'
            this.CountDown = 60
            this.isDisabled = false
          }
        }, 1000)
        const params = {
          phone: this.formAccount.username,
          configId: 9
        }
        getSmsCaptcha(params)
          .then((res) => {
            if (res.success) {
              this.$message.success('发送成功')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-modal-title {
  text-align: left;
}
</style>
<style lang="less">
.draw-user {
  .ant-drawer-header {
    .ant-drawer-title,
    .ant-drawer-close {
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
    }
    .ant-drawer-title {
      height: 47px;
      display: flex;
      align-items: center;
    }
    .ant-drawer-close {
      height: 80px;
    }
  }
  .flex {
    display: flex;
  }
  .box-card {
    padding: 28px 24px;
  }
  .box-line {
    border-bottom: 1px solid #eeeeee;
  }
  .title-box {
    font-weight: 700;
    font-size: 18px;
    color: #333333;
    margin-bottom: 24px;
    align-items: center;
    .title-icon {
      width: 18px;
      height: 18px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-right: 6px;
    }
  }
  .drawer-user {
    .title-icon {
      background-image: url('../../../assets/home/user.svg');
    }
    .user-detail {
      .user-detail-right {
        flex: 1;
        margin-left: 16px;
        .flex {
          justify-content: space-between;
          align-items: center;
        }
        .user-name-box,
        .user-id-box {
          margin-bottom: 15px;
          .user-name {
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #333333;
          }
        }
        .user-id {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
        }
        .copy {
          width: 60px;
          height: 20px;
          border: 1px solid #1677ff;
          border-radius: 2px;
          text-align: center;
          line-height: 20px;
          cursor: pointer;
          color: #1677ff;
        }
        .vip-not-bg {
          width: 65px;
          height: 16px;
          background: url('../../../assets/home/not-vip.jpg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .vip-bg {
          width: 65px;
          height: 16px;
          background: url('../../../assets/home/vip.jpg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .vip-btn {
          font-size: 12px;
          color: #fc9a08;
          cursor: pointer;
          text-decoration: underline;
        }
        .vip-box-time {
          margin-top: 5px;
        }
      }
    }
  }
  .balance-detail {
    .title-icon {
      background-image: url('../../../assets/home/mony.svg');
      transform: scale(1.2);
    }
    .balance,
    .paymant {
      font-weight: 300;
      font-size: 16px;
      color: #333333;
    }
    .balance {
      margin-bottom: 16px;
    }
    .balance-number {
      color: rgba(252, 154, 8, 1);
    }
    .balance-btn {
      width: 60px;
      margin-left: 30px;
      height: 24px;
      background: #fc9a08;
      border-radius: 2px;
      text-align: center;
      font-weight: 300;
      font-size: 12px;
      line-height: 24px;
      cursor: pointer;
      color: #ffffff;
    }
    .paymant-btn {
      color: #1677ff;
      cursor: pointer;
    }
  }
  .vip-detail {
    .title-icon {
      background-image: url('../../../assets/home/vip.svg');
    }
    .vip-box {
      background: #f7f7f7;
      border-radius: 4px;
      padding: 12px;
    }
    .vip-btn {
      height: 38px;
      background: linear-gradient(90deg, #535353 0%, #aaaaaa 100%);
      border-radius: 2px;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      font-weight: 500;
      margin-top: 20px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .layout-btn-box {
    padding: 0 24px 24px 24px;
  }
  .layout-btn {
    height: 40px;
    background: #fc9a08;
    border-radius: 4px;
    font-weight: 300;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    cursor: pointer;
  }
  .layout-btn-edit {
    height: 40px;
    background: #1677ff;
    border-radius: 4px;
    font-weight: 300;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
