import request from '@/utils/request'

export function dictDetail (id) {
  return request({
    url: '/api/back/employmentSupport/dict/' + id,
    method: 'get'
  })
}

export function getDictListByNames (names) {
  return request({
    url: '/api/back/employmentSupport/dict/names',
    method: 'get',
    params: {
      names: names.join(',')
    }
  })
}

export function dictAdd (data) {
  return request({
    url: '/api/back/employmentSupport/dict',
    method: 'post',
    data
  })
}

export function dictEdit (data) {
  return request({
    url: '/api/back/employmentSupport/dict',
    method: 'put',
    data
  })
}

export function dictDelete (params) {
  return request({
    url: '/api/back/employmentSupport/dict',
    method: 'delete',
    params
  })
}

export function dictPage (params) {
  return request({
    url: '/api/back/employmentSupport/dict/page',
    method: 'get',
    params
  })
}

export default { dictPage, dictAdd, dictEdit, dictDelete, dictDetail }
