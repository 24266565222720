import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'

// default router permission control
// import permission from './modules/permission'
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  state: {},
  mutations: {},
  actions: {},
  getters
})
