import { Modal } from 'ant-design-vue'

export default {
    name: 'Modal',
    functional: true,
    render(h, context) {
        const { data, children,scopedSlots } = context
        return h(Modal, {
            ...data,
            props: {
                ...data.props,
                maskClosable: false
            },
            scopedSlots
        }, children)
    }
}