<template>
  <!-- <a-dropdown placement="bottomCenter" overlayClassName="avatar__menu">
    <span class="ant-pro-account-avatar">
      <a-avatar
        size="small"
        :src="currentUser.enterpriseInfoDto.logo"
        class="antd-pro-global-header-index-avatar"
      />
      <span>{{ userInfo.username }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown> -->
  <div class="avatar__menu" @click="$refs.drawer.show()">
    <span class="ant-pro-account-avatar">
      <a-avatar size="small" :src="currentUser.enterpriseInfoDto.logo" class="antd-pro-global-header-index-avatar" />
      <span>{{ userInfo.username }}</span>
    </span>
    <drawer ref="drawer" type="console" />

  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import drawer from '@/views/home/components/drawer.vue'

export default {
  name: 'AvatarDropdown',
  components: {
    drawer
  },
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  created () {},
  methods: {
    handleToCenter () {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout (e) {
      Modal.confirm({
        title: '系统提示',
        content: '确认退出登录吗？',
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'home' })
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less">
.avatar__menu {
  .action {
    margin-right: 8px;
  }
  .menu {
    width: 140px;
  }
}
.ant-pro-account-avatar {
  padding: 0px !important;
}
</style>
